import PropTypes from "prop-types"
import React,{useState} from "react"
import Modal from 'react-modal';
import Auth from './auth';

import logo from '../assets/img/logo_horizontal.png';
import logoWhite from '../assets/img/logo_horizontal.png-white.png';
import userMenu from '../assets/img/ProfileIcon.svg';
import  firebase  from "gatsby-plugin-firebase";
import { getUser, isLoggedIn, logout } from "../helpers/auth";

class Header extends React.Component  {

    state = { show: false };

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };  


    componentDidMount = () =>{   
        window.onscroll = function() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        };

        var header = document.getElementById("header");
        var sticky = header.offsetTop;      
    }

    render(){
        return (
            <header id="header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                        <a className="navbar-brand" href="/">
                            <img src={logo} alt="logo" className="default"/>
                            <img src={logoWhite} alt="logo" className="white"/>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-center-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                                </li>
                            </ul>
                            <ul className="navbar-nav my-2 my-lg-0">
                                <li className="nav-item">
                                    { this.props.displayName && <a href="/newmeeting" > Dashboard </a>}
                                </li>
                                <li className="nav-item">
                                    { this.props.displayName && <a href="/" onClick={event => { event.preventDefault();logout(firebase).then(() => window.location.reload());}}>
                                        {this.props.displayName} (logout)
                                        </a>
                                    }
                                </li>
                                {/*<li className="nav-item">
                                    <a className="nav-link" href="https://play.google.com/store/apps/details?id=in.meetnow">Play Store</a>
                                </li> 
                                <li className="nav-item">
                                    <a className="nav-link" href="https://apps.apple.com/us/app/meetnow-indias-conference/id1510590657">App Store</a>
                                </li>*/}
                                <li className="nav-item">
                                    <a className="nav-link" href="#" onClick={this.showModal}>
                                        <img src={userMenu} alt="user-menu" />
                                    </a>
                                </li>              
                            </ul>            
                        </div>
                    </nav>       

                </div> 
                <Modal isOpen={this.state.show} > 
                    <div className="banner-left">
                        <span className="modal-close-btn" onClick={this.hideModal}>x</span>
                        <Auth/>
                    </div>                     
                </Modal>          
            </header>  
        )
    };
}


export default Header