import React,{ useState,useEffect} from "react";
import  firebase  from "gatsby-plugin-firebase";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser } from "../helpers/auth";
import { getUserMetaData } from "../helpers/firebase";

const getUiConfig = (auth) => {
  if (typeof window !== 'undefined') {
    return {
            signInFlow: 'popup',
            signInOptions: [
            auth.GoogleAuthProvider.PROVIDER_ID,
            auth.EmailAuthProvider.PROVIDER_ID
        ],
        signInSuccessUrl: '/dashboard',
        callbacks: {
        signInSuccessWithAuthResult: (result) => {
            setUser(result.user);
            getUserMetaData(result.user.uid);

            return true;
          }
        }
    };
  }
  return null; 
}

const Auth = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        {firebase && <StyledFirebaseAuth uiConfig={getUiConfig(firebase.auth)} firebaseAuth={firebase.auth()}/>}
      </>
    )
  } else {
    return null
  }
}
export default Auth