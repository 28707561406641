import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Auth from './auth';

const LoginModal = ({open, onClose, ...props}) => {
    return (
        <Modal 
            open={open || false}
            onClose={onClose || function () {}}
            {...props}
        >
            <div className="c_modal ps_modal">
                <div className="sub_cmodal">
                    <div className="modal_header">
                        <div className="title">
                            Login
                        </div>
                        <div className="btn_close" onClick={() => onClose()}>
                            <FontAwesomeIcon className="fa_icon" icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal_content">
                        <Auth />
                    </div>
                </div>
            </div>
        </Modal>
    ) 
}

export default LoginModal;