import React, { useState } from "react"
import footerLogo from '../assets/img/logo_horizontal.png';
import LoginModal from "./login-modal";

const Footer = () => {
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

	return (
		<div>
			<LoginModal 
				open={isLoginModalOpen}
				onClose={() => setIsLoginModalOpen(false)}
			/>
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-sm-12">
							<div className="footer-logo">
								<a href="/">
									<img src={footerLogo} alt="footer-logo"/>
								</a>
							</div>
						</div>
						<div className="col-md-3 col-sm-12 copy-right">
							<p>&copy; A appify mobile technologies product</p>
							<address>
								
							</address>
						</div>
						<div className="col-md-3 col-sm-12">
							<ul className="imp-links">
								<li className="links"><a href="/dashboard">Home</a></li>
								<li className="links"><a href="#" onClick={() => setIsLoginModalOpen(true)}>Log in</a></li>
								<li className="links"><a href="/about-us">About us</a></li>
								<li className="links"><a href="/dashboard">Contact us</a></li>
								<li className="links"><a href="/dashboard">Support</a></li>	
								<li className="links"><a href="/privacy-policy">Privacy Policy</a></li>						
							</ul>
						</div>
						<div className="col-md-3 col-sm-12">
							<ul className="social-links">
								<li className="links">
									<a href="https://www.linkedin.com"><i className="fab fa-linkedin"></i> Linkedin</a>
								</li>												
								<li className="links">
									<a href="https://www.facebook.com/meetnow.in/"><i className="fab fa-facebook-square"></i> Facebook</a>
								</li>
								<li className="links">
									<a href="https://twitter.com/MeetNow_in"><i className="fab fa-twitter-square"></i> Twitter</a>
								</li>						
							</ul>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}
export default Footer